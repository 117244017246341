import api from './api';

class CatalogoDigitalService {

    getNombresFotos(denominacion, clase, regla) {
        return api.get(`/api/catalogo_digital/fotos`, {
            params: {
                denominacion: denominacion,
                clase: clase,
                regla: regla
            }
        });
    }

    getNombresFotosAnt(denominacion, clase, regla) {
        const encodedClase = encodeURIComponent(clase);  // Codifica el parámetro 'clase'
        return api.get(`/api/catalogo_digital/fotos/${denominacion}/${encodedClase}/${regla}`);
    }

    consultar(params) {
        return api.post(`/api/catalogo_digital/consultar`, params)
    }
    
    getEnlacesAnexos() {
        return api.get(`/api/enlaces-anexos`)
    }

}

export default new CatalogoDigitalService();

